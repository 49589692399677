@use '@angular/material' as mat;
@include mat.core();

$my-app-primary: mat.m2-define-palette(mat.$m2-brown-palette, 900);
$my-app-accent: mat.m2-define-palette(mat.$m2-amber-palette);

$my-app-theme: mat.m2-define-light-theme($my-app-primary, $my-app-accent);

@include mat.all-component-themes($my-app-theme);

// $theme: mat.define-theme((
//     color: (
//         theme-type: dark,
//         primary: mat.$m2-brown-palette,
//     ),
//     typography: (
//         brand-family: 'Arial',
//         bold-weight: 900
//     ),
//     density: (
//     scale: -1
//     )
// ));

body {
    margin: 0px;
    padding: 0px;
    background-color: #3e2723;
}